import { useExperiments } from '@gonfalon/experiments';
import { useProjectKey } from '@gonfalon/router';

export function useHasRunningExperiment(
  flagKey: string,
  environmentKey: string,
): { onRule: boolean; onFallthrough: boolean } {
  const projectKey = useProjectKey();

  const { status, data } = useExperiments({
    projectKey,
    environmentKey,
    params: {
      filter: {
        flagKey,
        kind: 'experiment',
      },
    },
  });

  const experiments = data?.items.filter((exp) => exp?.currentIteration?.status === 'running') ?? [];

  if (status !== 'success') {
    return { onRule: false, onFallthrough: false };
  }

  return {
    onRule: experiments.some((exp) => exp.currentIteration?.flags?.[flagKey]?.targetingRule !== 'fallthrough'),
    onFallthrough: experiments.some((exp) => exp.currentIteration?.flags?.[flagKey]?.targetingRule === 'fallthrough'),
  };
}
